import React, { Component } from 'react'

import { Box, Button, Container, Grid, Paper, Snackbar, TextField, Typography, makeStyles } from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table";
import { SendOutlined } from '@material-ui/icons';
import Check from '@material-ui/icons/Check';
import { Alert } from '@material-ui/lab';

class CreateMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.match.params.id,
            title: null,
            message: null,
            file: null,
            image: null,
            openNotif: false,
            onLoad: false,
            dataNotfound: false
        }
    }

    
    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({onLoad: true})
        this.onStart()
    }

    onStart = async () => {
        const { id } = this.state;
        this.resetState()
        const res = await api.get(`mobile-message/${id}`);
        
        const {data} = res.data;
        console.log(data);

        if (data === null) {
            this.setState({ onLoad: false, dataNotfound: true });
        } else {
            this.setState({ title: data.title, message: data.message, image: data.image, onLoad: false });
        }
    };

    resetState = () => {
        this.setState({
            title: null,
            message: null,
            file: null,
            image: null,
            openNotif: false,
            notif_severity: 'success',
            notif_message: '',
            notif_position: 'center',
            dataNotfound: false
        })
    }

    onAttach = (e, f) => {
        let { state } = this;
        let validated = false;

        if (e.target.files[0]) {
            const fileSize = e.target.files[0].size / 1024
            validated = fileSize > 1000
            
            if (validated === false) {
                state[f] = e.target.files[0];
                this.setState({ state });
            } else {
                this.handleChangeNotif(true, "Image size must be 1 MB or smaller", 'error', 'center')
            }
        }

    };

   handleChange = (e, f) => {
        let { state } = this;
        state[f] = e.target.value;
        this.setState({ state });
    };

    handleChangeNotif = (val, message="", severity="success", position="right") => {
        let { state } = this;
        state['openNotif'] = val;
        state['notif_message'] = message
        state['notif_severity'] = severity
        state['notif_position'] = position

        this.setState({ state });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const { id, title, message, file } = this.state;

        let formData = new FormData();
        formData.append('title', title)
        formData.append('message', message)
        formData.append('file', file)

        try {
            const res = await api.post(`mobile-message/${id}?_method=put`, formData);
            let { data } = res;
            
            if (data.error) {
                alert(data.message);
                return;
            }
            
            this.handleChangeNotif(true, data.message, "success", "right")
            // this.onStart()
            setTimeout(() => {
                window.location.href = "/mobile-notif"
            }, 500);
          } catch (e) {
            console.log(e);
            this.handleChangeNotif(true, "Failed to save the data, something went wrong!", "error", "right")
          }
    };
    

   render() {
        const {title, message, file, image, openNotif, onLoad, dataNotfound, notif_message, notif_severity, notif_position} = this.state;

        return (
            <Container id="enquiry" maxWidth="xl" >
                <Paper square className="padding spaceBottom">
                    {onLoad ? (
                        <h4 style={{textAlign: 'center', padding: '25px'}}>
                            Please wait
                            Loading...
                        </h4>
                    ) : (
                        <>
                            {dataNotfound ? (
                                <>
                                    <h3 style={{textAlign: 'center', padding: '25px'}}>
                                        The data not found (404)
                                    </h3>
                                    <Button
                                        color="default"
                                        href='/mobile-notif'
                                    >
                                        Back
                                    </Button>
                                </>
                            ) : (
                                <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                                    <Grid container className={'flexGrow'} spacing={2} >
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="center" spacing={3} style={{justifyContent: 'center'}}>
                                                <Grid item xs={6}>
                                                    <h2>Edit message</h2>
                                                    <br />
                                                    <br />
                                                    <TextField id="standard-basic" label="TITLE" placeholder='Enter the title' fullWidth margin="normal" value={title} onChange={(e) => this.handleChange(e, "title")} defaultValue={title} />
                                                    <TextField id="filled-basic" label="MESSAGE" placeholder='Enter the message' multiline rows={5} fullWidth margin="normal" value={message} onChange={(e) => this.handleChange(e, "message")} />
            
                                                    <Grid item xs={6}>
                                                        <Typography className="bold space">
                                                            Image File
                                                        </Typography>

                                                        {image && <img src={image} width={`200`} />}
                                                        
                                                        <input
                                                            accept="image/*"
                                                            id="profile-nric-file"
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            onChange={(e) => this.onAttach(e, "file")}
                                                        />
                                                        <label htmlFor="profile-nric-file" style={{display: 'block'}}>
                                                            <Button
                                                                variant="contained"
                                                                component="span"
                                                                size='small'
                                                            >
                                                                Browse
                                                            </Button>
                                                            <small style={{display: 'block', fontStyle: 'italic', marginTop: '5px'}}>max file size is 1MB</small>
            
                                                            {file && (
                                                                <Typography style={{color: '#16a085', marginTop: '10px'}}>Attached: {file.name}</Typography>
                                                            )}
                                                        </label>
                                                    </Grid>
                                                    <Grid container spacing={3} style={{marginTop: '20px'}}>
                                                        <Grid item xs={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                startIcon={<Check />}
                                                                style={{width: '100%'}}
                                                                type="submit"
                                                            >
                                                                Save Message
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={3}>
            
                                                        <Button
                                                            variant="contained"
                                                            color="default"
                                                            href='/mobile-notif'
                                                        >
                                                            Back
                                                        </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </>
                    )}
                    
                </Paper>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: notif_position }} open={openNotif} autoHideDuration={6000} onClose={() => this.handleChangeNotif(false, notif_message, notif_severity, notif_position)}>
                    <Alert onClose={() => this.handleChangeNotif(false, notif_message, notif_severity, notif_position)} severity={notif_severity}>
                        {notif_message}
                    </Alert>
                </Snackbar>
            </Container>
        )
   }
}

export default CreateMessage
