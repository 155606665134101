import React, { Component, Fragment } from "react";
import api from 'services/api';
import hp from 'services/hp';
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Icon,
  Input,
  Tab,
  Tabs,
  Typography,
  TextField,
} from '@material-ui/core'
import { 
  Autocomplete 
} from "@material-ui/lab";
import TemplateEditor from "./template-editor";
import PreviewTemplate from "./preview";
import SignerTab from "./signer-tab";

class ContractTemplateDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
      campaigns: null,
      investments: null,
      selectedCampaign: null,
      selectedInvestment: null,
      template: null,
      activeTab: 0,
      isChanged: false,
      alertOpen: false,
      alertSuccess: true,
      alertMessage: null,
      signer: {
        activeTab: 0,
      },
      preview: {
        images: [],
        thumbnails: [],
        path_url: null,
        total: 0,
        main_total: 0,
        signer_total: 0,
        attachment_total: 0,
        isPreviewed: false,
      }
    }
  }

  async componentDidMount () {
    if (!this.state.campaigns) {
      await this.loadOptions('campaign');
      this.setState({campaigns: this.getOptions('campaign')});
    }

    await this.loadInvestmentOptions();

    this.loadTemplate();
  }

  loadInvestmentOptions = async () => {
    await this.loadOptions('investment');
    await this.setState({investments: this.getOptions('investment')});
  }

  loadTemplate = async () => {
    await api.get(`contract-template/${this.props.match.params.id}`).then(res => {
      this.setState({template: res.data});
    }).catch(error => {
      // Next Task : Handle error ( make re-useable )
    });
  }
  
  getTemplate = () => this.state.template;

  getTemplateContent = (type) => {
    const { template } = this.state;
    if (type === 'main') {
      return template.content;
    }
    if (type === 'signer') {
      return template.sign_content;
    }
    if (type === 'attachment') {
    return template.attachment_content;
    }
  }

  setTemplateContent = (type, content, isChanged = true) => {
    const { template, preview } = this.state;
    switch (type) {
      case 'main':
        template.content = content;
        break;
      case 'signer':
        template.sign_content = content;
        break;
      case 'attachment':
        template.attachment_content = content;
        break;
    
      default:
        break;
    }
    preview.isPreviewed = !isChanged;
    this.setState({template, isChanged, preview});
  }

  updateTemplate = (e, isChanged = false) => {
    const templateData = (({ name, content, sign_content, attachment_content, signer_json }) => 
      ({ name, content, sign_content, attachment_content, signer_json })
    )(this.state.template);
    api.put(`contract-template/${this.state.template.id}`, templateData)
      .then(res => {
        this.setState({alertOpen: true, alertSuccess: true, alertMessage: 'Update Successed', isChanged});
      }).catch(error => {
        this.setState({alertOpen: true, alertSuccess: false, alertMessage: 'Update Failed'});
      });
  }

  toggleActiveTab = (e, activeTab) => this.setState({activeTab});

  loadOptions = async (type) => {
    if (type === 'campaign') {
      await api.get('select/campaigns').then(res => {
        this.setState({campaigns: res.data.campaigns});
      });
    }
    if (type === 'investment') {
      if (this.state.selectedCampaign) {
        await api.get(`select/investments/${this.state.selectedCampaign.id}`).then(res => {
          this.setState({investments: res.data.investments});
        });
      }
    }
  }

  getOptions = (type) => {
    if (type === 'campaign') {
      return this.state.campaigns;
    }
    if (type === 'investment') {
      return this.state.investments;
    }
  }

  selectOption = (type, option) => {
    if (type === 'campaign') {
      this.setState({selectedCampaign: option});
    }
    if (type === 'investment') {
      this.setState({selectedInvestment: option});
    }
  }

  getSelectedOption = (type, option) => {
    if (type === 'campaign') {
      return this.state.selectedCampaign;
    }
    if (type === 'investment') {
      return this.state.selectedInvestment;
    }
  }

  render () {
    if (!this.state.template) {
      return hp.Loading();
    } else {
      return (
        <Fragment>
          <Container maxWidth="xl">
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={10}>
                    <Typography color="secondary" gutterBottom>Contract Template</Typography>
                    <Input 
                      className="font-larger w-100"
                      defaultValue={this.state.template.name} 
                      onChange={(e, isChanged = true) => {
                        const { template } = this.state;
                        template.name = e.target.value;
                        this.setState({template, isChanged});
                      }} 
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className="xy-center">
                    <Button 
                      className="font-larger w-100"
                      variant="contained" 
                      color="primary" 
                      disabled={!this.state.isChanged} 
                      startIcon={<Icon>save</Icon>}
                      onClick={this.updateTemplate}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    { !this.state.campaigns && hp.Loading() }
                    {
                      this.state.campaigns && 
                      <Autocomplete
                        value={this.getSelectedOption('campaign')}
                        disabled={this.state.campaigns == null}
                        id="select-campaign"
                        options={this.state.campaigns}
                        getOptionLabel={(option) => `${option.acronim} - ${option.name}`}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Campagin"
                            variant="outlined"
                          />
                        )}
                        onChange={async (event, option) => {
                          await this.setState({investments: null});
                          await this.selectOption('campaign', option);
                          if (option) {
                            this.loadInvestmentOptions();
                          }
                        }}
                        filterOptions={this.filterOptions}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    { this.state.investments && this.state.campaigns &&
                      <Autocomplete
                        value={this.getSelectedOption('investment')}
                        disabled={!this.getSelectedOption('campaign')}
                        id="select-investment"
                        options={this.state.investments}
                        getOptionLabel={(option) =>
                          "Investmen ID " +
                          option.id +
                          " > " +
                          option.name
                        }
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Investment"
                            variant="outlined"
                          />
                        )}
                        onChange={async (event, option) => {
                          await this.selectOption('investment', option);
                          if (option) {
                            const {preview} = this.state;
                            preview.isPreviewed = false;
                            this.setState(preview);
                          }
                        }}
                        filterOptions={this.filterOptions}
                      />
                    }
                  </Grid>
                </Grid>
              </CardContent>
              <AppBar position="static">
                <Tabs value={this.state.activeTab} onChange={this.toggleActiveTab}>
                  <Tab label="Main"></Tab>
                  <Tab label="Signer"></Tab>
                  <Tab label="Attachments"></Tab>
                  <Tab label="Preview"></Tab>
                </Tabs>
              </AppBar>
            </Card>
            {
              this.state.activeTab === 0 && 
              <TemplateEditor 
                content={this.getTemplateContent} 
                type="main"
                id="main-content"
                className="space"
                onChange={this.setTemplateContent}
              />
            }
            {
              this.state.activeTab === 1 
              &&
              <SignerTab parent={this}/>
            }
            {
              this.state.activeTab === 2 && 
              <TemplateEditor 
                content={this.getTemplateContent} 
                type="attachment" 
                id="attachment-content"
                className="space"
                onChange={this.setTemplateContent}
              />
            }
            {
              this.state.activeTab === 3 && 
              <PreviewTemplate 
                parent={this}
                // loadOptions={this.loadOptions} 
                // getOptions={this.getOptions} 
                // selectOption={this.selectOption}
                // getSelectedOption={this.getSelectedOption}
                // getTemplate={this.getTemplate}
                // updateTemplate={this.updateTemplate}
              />
            }
          </Container>
          {hp.alert(this.state.alertOpen, this.state.alertSuccess, this.state.alertMessage, () => this.setState({alertOpen: false}))}
        </Fragment>
      )
    }
  }
}

export default ContractTemplateDetail;