import React, { Component } from 'react'

import { Container, Button, Grid, Modal, DialogTitle, DialogContent, Dialog, Snackbar } from '@material-ui/core'
import api from 'services/api'
import MaterialTable, { MTableToolbar } from "material-table";
import AddIcon from "@material-ui/icons/Add";
import { Alert } from '@material-ui/lab';

class Page extends Component {
   constructor(props) {
      super(props)

      this.state = {
         dataSelected: {id: null},
         detailOpen: false,
         alert_message: '', 
         alert_severity: 'success',
         openNotif: false,
      }
      this.tableRef = React.createRef();
   }

   componentDidMount() {
      window.scrollTo(0, 0)
   }

   onDetailModalOpen = (data) => {
      let { state } = this;
      state.dataSelected = data
      state.detailOpen = true

      this.setState({ state });
   }

   onDetailModalClose = () => {
      let { state } = this;
      state.detailOpen = false
      this.setState({ state });
      
      setTimeout(() => {
         state.dataSelected = {id: null}
         this.setState({ state });
      }, 100);

   }

   onNotifOpen = (message, severity) => {
      this.setState({ alert_message: message, alert_severity: severity, openNotif: true });
   }

   render() {
      const { detailOpen, dataSelected, alert_message, alert_severity, openNotif } = this.state

      return (
         <Container id="enquiry" maxWidth="xl" >
            <MaterialTable
               tableRef={this.tableRef}
               columns={[
                  { title: 'No', field: 'id', render: rowData => rowData.tableData.id + 1 },
                  { title: 'Title', field: 'title'},
                  { title: "Message", field: "message" },
                  { title: "Sent", field: "is_sent", type: "boolean", },
               ]}
               title="Mobile Messages"
               options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                  actionsColumnIndex: -1,
                  filtering: true,
                  search: false,
               }}
               data={(query) =>
                  new Promise(async (resolve) => {
                    let field = query.orderBy ? query.orderBy.field : "";
                    let filters = [];
                    if (query.filters.length > 0) {
                      query.filters.map((data) => {
                        let column = {};
                        let value = [];
                        column.field = data.column.field;
                        value = data.value;
                        filters.push({ column: column['field'], value: value });
                        return data;
                      });
                      filters = JSON.stringify(filters);
                    }

                    const result = await api.get(`mobile-message?limit=${
                     query.pageSize
                     }&page=${query.page + 1}&search=${query.search}
                           &orderDirection=${
                              query.orderDirection
                           }&field=${field}&filters=${filters}`);
                    
                    const { data } = result;
                    
                    resolve({
                      data: data.data,
                      page: query.page,
                      totalCount: data.total,
                    });
                  })}
               editable={{
                  onRowDelete: oldData =>
                  new Promise (async(resolve) => {
                     await api.delete(`mobile-message/${oldData.id}`).then(res => {
                        // console.log(res);
                        const { data } = res
                        this.onNotifOpen(data.message, 'success')
                     }).catch(error => {
                        console.log(error);
                        this.onNotifOpen("Failed to delete message, something went wrong!", 'error')
                     })
                     resolve()
                  })
               }}
               actions={[
                  {
                     icon: 'fullscreen',
                     tooltip: 'Show data',
                     onClick: (event, rowData) => {
                        this.onDetailModalOpen(rowData)
                     }
                  },
                  {
                     icon: 'edit',
                     tooltip: 'Edit data',
                     onClick: (event, rowData) => {
                        this.props.history.push(`mobile-notif/edit/${rowData.id}`)
                     }
                  },
                  rowData => ({
                     icon: 'send',
                     tooltip: 'Send notification',
                     onClick: async (event, rowData) => {
                        const res = await api.post(`mobile-message/send/${rowData.id}`);
                        let { data } = res;

                        if (data.error) {
                           this.setState({ alert_message: data.message, alert_severity: 'error', openNotif: true });
                        } else {
                           this.setState({ alert_message: data.message, alert_severity: 'success', openNotif: true });
                        }

                        this.tableRef.current && this.tableRef.current.onQueryChange();
                     },
                     disabled: rowData.is_sent === 1,
                   })
               ]}
               components={{
                  Toolbar: (props) => (
                     <Grid container spacing={3} style={{padding: '0 15px 15px'}}>
                        <Grid item xs={6}>
                           <h3>Mobile Message (Notification)</h3>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'right'}}>
                        <Button
                          type="button"
                          variant="contained"
                          style={{
                            marginLeft: 5,
                            backgroundColor: "#0069d9",
                            color: "#fff",
                          }}
                          startIcon={<AddIcon />}
                          onClick={() => this.props.history.push("mobile-notif/create")}
                        >
                          Add Mobile Message
                        </Button>
                        </Grid>
                    </Grid>
                  ),
                }}


            />

            <Dialog onClose={this.onDetailModalClose} aria-labelledby="customized-dialog-title" open={detailOpen}>
               <DialogTitle id="customized-dialog-title" onClose={this.onDetailModalClose}>
                  Detail Message
               </DialogTitle>

               <DialogContent dividers>
                  {dataSelected ? (
                     <>
                        <h3>{dataSelected.title}</h3>
                        <p>{dataSelected.message}</p>
                        {dataSelected.image 
                           && <img src={dataSelected.image} loading='lazy' width={'100%'} />
                        }
                     </>
                  ) : (
                     <h3 style={{padding: "20px 15px"}}>Data Notfound</h3>
                  )}
               </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openNotif} autoHideDuration={6000} onClose={() => {
               this.setState({ openNotif: false });
            }}>
               <Alert onClose={() => {
                  this.setState({ openNotif: false });
               }} severity={alert_severity}>
                  {alert_message}
               </Alert>
            </Snackbar>
         </Container>
      )
   }
}

export default Page
